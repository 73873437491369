var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":_vm.showSchedule ? 'Schedule Message' : 'Send Message',"back-button-text":"Previous"},on:{"on-complete":_vm.SubmitForm}},[_c('tab-content',{attrs:{"title":"Campaign Type","before-change":_vm.validationFormType}},[_c('validation-observer',{ref:"campaignTypeRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Campaign Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Campaign Type","label-for":"campaign_type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"campaign_type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.campaignTypes,"reduce":function (type) { return type.value; },"label":"name"},model:{value:(_vm.composeData.type),callback:function ($$v) {_vm.$set(_vm.composeData, "type", $$v)},expression:"composeData.type"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Campaign Type ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Contact Group","before-change":_vm.validationFormAccount}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Sender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"From","label-for":"sender","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"sender","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.senders,"reduce":function (name) { return name.id; },"label":"name"},on:{"open":_vm.fetchSenders},model:{value:(_vm.composeData.sender),callback:function ($$v) {_vm.$set(_vm.composeData, "sender", $$v)},expression:"composeData.sender"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Sender Name ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"To","label-for":"groups","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"groups","multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groups,"label":"name"},model:{value:(_vm.composeData.to),callback:function ($$v) {_vm.$set(_vm.composeData, "to", $$v)},expression:"composeData.to"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Contact Groups ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Message","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Type your message Below ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Type your message below. Optionally provide a subject")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Subject","label-for":"subject"}},[_c('validation-provider',{attrs:{"name":"Subject","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subject","placeholder":"Subject","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.placeHolders.length > 0)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Place Holders: ")])])],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((_vm.placeHolders),function(tag){return _c('b-card',{key:tag,staticClass:"shadow-none border mt-1 mr-1 mb-1",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(
                  /\s/g,
                  '_'
                )) + "_")},on:{"click":function($event){return _vm.insertTag(tag)}}},[_vm._v(" insert ")])],1)}),1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Message","label-for":"message","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{ref:"refMessage",attrs:{"id":"message","placeholder":"Message","rows":"8","no-resize":"","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.message),callback:function ($$v) {_vm.$set(_vm.composeData, "message", $$v)},expression:"composeData.message"}}),(
                    _vm.composeData.message !== undefined &&
                      _vm.composeData.message.length > 0
                  )?_c('div',[_c('b-alert',{staticClass:"mt-2 mb-2",attrs:{"show":"","variant":"dark"}},[_c('p',{staticClass:"m-2 mt-2 mb-2"},[_c('small',[_vm._v(" Message Length: Includes "),_c('strong',[_vm._v("STOP *456*9*5#")]),_vm._v(" Suffix")]),_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.composeData.message.length + 14))])],1)])],1):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Preview & send","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Confirm Details ")])]),_c('b-col',{attrs:{"md":"12"}},[(
                _vm.composeData.subject !== undefined &&
                  _vm.composeData.subject.length > 0
              )?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(" Subject: "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.composeData.subject))])])]):_vm._e(),_c('div',{staticClass:"mb-2"},[_vm._v(" To: "),_vm._l((_vm.composeData.to),function(grp){return _c('span',{key:grp.id},[_c('span',{staticClass:"ml-1 text-muted"},[_vm._v("[ "+_vm._s(grp.name)+" - "+_vm._s(grp.num_contacts)+" ] ")])])})],2),_c('div',{staticClass:"mb-2"},[_vm._v(" Total Contacts:"),_c('span',{staticClass:"text-muted"},[_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.numOfContacts)+" ")])],1)]),_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v(" Message: ")]),_c('b-form-textarea',{staticClass:"mb-2",attrs:{"id":"textarea-plaintext","plaintext":"","value":_vm.composeData.message,"rows":"4","no-resize":""}})],1),_c('div',[_c('b-alert',{staticClass:"p-2",attrs:{"show":""}},[_c('small',[_vm._v("Approximately "),_c('b',{staticClass:"text-dark mx-auto"},[_vm._v(" "+_vm._s(_vm.estimateUnits)+" ")]),_vm._v(" SMS Credits will be used")])])],1)])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.scheduleSwitchChecked,"name":"check-button","switch":"","inline":""},on:{"change":_vm.toggleScheduleSwitch}},[_vm._v(" Schedule Message ")])],1)],1)],1),(_vm.showSchedule)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('h5',[_vm._v("Date")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
              }},model:{value:(_vm.composeData.sendAtDate),callback:function ($$v) {_vm.$set(_vm.composeData, "sendAtDate", $$v)},expression:"composeData.sendAtDate"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('h5',[_vm._v("Time")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true,
              }},model:{value:(_vm.composeData.sendAtTime),callback:function ($$v) {_vm.$set(_vm.composeData, "sendAtTime", $$v)},expression:"composeData.sendAtTime"}})],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }